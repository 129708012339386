import SaylStore from '@/bootstrap/stores'

export default {
  inject: ['$embed'],

  computed: {
    i18n() {
      return SaylStore.bootstrap().i18n
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },
  },

  methods: {
    toCurrency(value, signed = false) {
      value = Number.parseFloat(value).toFixed(2)
      let sCurrency = this.shop.currency;
      let ss = sCurrency && sCurrency.symbol || null;
      let ret = this.i18n.toCurrency(value, ss);

      if (signed === true) {
        let pre = value < 0 ? '' : '+';
        ret = pre + ret;
      }

      return ret;
    },

    toRangeCurrency({ min, max, signed = false }) {
      return `${this.toCurrency(min, signed)} - ${this.toCurrency(max, signed)}`
    }
  }
}
