<template>
  <button
    :class="classes"
    :disabled="isDisabled"
    @click="onItemClick">
    <figure
      class="card-product__figure"
      :class="{ '-no-figure': productPicture === '' }">
      <ui-image
        :alt="product.name"
        class="card-product__illustration"
        :class="{ '-no-figure': productPicture === '' }"
        :height="200"
        :src="productPicture"
        url-complete
        :width="200"
        v-if="productPicture != null && imageType === 'image'"
      />

      <video
        autoplay
        class="card-product__illustration"
        loop
        muted
        playsinline
        :src="productPicture"
        v-if="productPicture != null && imageType === 'video'"
      />

      <span
        class="card-product__perks"
        v-if="perksCount > 0" 
      >{{ $t('conn3ct-store.perks_count', { count: perksCount }) }}</span>
    </figure>

    <div class="card-product__body">
      <div class="card-product__row">
        <p class="card-product__title">{{ product.name }}</p>

        <span class="card-product__stock">{{ $t('conn3ct-store.stock', { stock: product.stock, supply: product.supply }) }}</span>
      </div>

      <!-- <div class="card-product__row">
        <span class="card-product__stock">{{ $t('conn3ct-store.stock', { stock: product.stock, supply: product.supply }) }}</span>
      </div> -->

      <div 
        class="card-product__row" 
        v-if="!hideDescr">
        <p
          class="card-product__description"
          ref="description"
          v-html="product.description"
        ></p>
      </div>

      <div 
        class="card-product__row" 
        v-if="!hidePrice">
        <product-price
          class="card-product__price"
          :product="product"
        />
      </div>
    </div>
  </button>
</template>

<script>
import CurrencyMixin from '@/helpers/currency'
import UiImage from '@/components/ui/image'
import ProductPrice from '@/modules/catalog/components/product/price'

export default {
  name: 'CatalogProductCard',

  components: {
    ProductPrice,
    UiImage
  },

  inject: [
    '$embed',
    '$nft',
    '$user',
  ],

  mixins: [
    CurrencyMixin,
  ],

  props:{
    disabled: {
      default: false,
      type: Boolean
    },
    hideDescr: {
      default: false,
      type: Boolean,
    },  
    hidePrice: {
      default: false,
      type: Boolean
    },
    interactive: {
      default: true,
      type: Boolean,
    },
    product: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      debounce: null,
      enabled: true,
      item: null,
      nft: null
    }
  },

  computed: {
    classes() {
      return {
        'card-product': true,
        '--interactive': this.interactive === true,
        '-disabled': this.isDisabled === true,
        '-hidden': this.isHidden === true,
        '-no-price': this.hidePrice === true,
        '-promo': this.product.inPromo,
        '-is-inverted': this.$basil.get(this.settings, 'is_layout_product_inverted', false) === true
      }
    },

    description() {
      let ret = this.$basil.get(this.product, 'description')
      return !this.$basil.isNil(ret) ? ret.split('\n').join('<br />') : null
    },

    isDisabled() {
      return this.disabled || this.$basil.get(this.product, 'stock', 0) === 0
    },

    imageType() {
      const type = this.$basil.get(this.product, 'imageType')
      
      if(type && type.includes('video')) {
        return 'video'
      }

      if(type && type.includes('image')) {
        return 'image'
      }

      return 'image'
    },

    isHidden() {
      return this.$basil.get(this.product, 'hidden', false)
    },

    perksCount() {
      return this.$basil.get(this.product, 'nft.perks_count', 0) || 0
    },

    price() {
      let v = this.$basil.get(this.product, 'unitPrice', 0)

      return this.toCurrency(v)
    },

    productPicture() {
      let ret = [
        this.$basil.get(this.product, 'picture', null),
        this.$basil.get(this.shop, 'logo', null),
      ].filter(s => !this.$basil.isNil(s))
      
      ret = ret.length > 0 ? ret[0] : null
      return ret
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    value() {
      return this.product
    },
  },

  methods: {
    onItemClick() {
      this.$router.push({ 
        name: 'sayl-front-catalog.product', 
        params: {
          embed: this.$route.params.embed,
          product: this.product.id
        }
      }).catch(() => {})
    },

    async reset() { 
      this.nft = this.$nft.findById(this.product.id)
    }
  },

  mounted() {
    try {
      $clamp(this.$refs.description, { clamp: 3 }) 
      this.reset()
    } catch (error) {}
  }
}
</script>
