<template>
  <layout-modal
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h3 class="layout-modal__title">{{ $t('conn3ct-store.tokens_list_title') }}</h3>
    </template>

    <article
      class="layout-modal__article flow">
      <div class="filters">
        <forms-checkbox
          v-model="availableOnly">
          {{ $t('conn3ct-store.tokens_list_filter_label') }}
        </forms-checkbox>
      </div>

      <div v-if="hasTokens" class="tokens-list">
        <div
          class="tokens-list__token token"
          v-for="token in tokensFiltered"
          :key="token.id"
          @click="() => select(token.tokenId)"
          :class="{'--selected': token.tokenId === selected, '--interactive': token.purchasable && !token.purchasing}">
          <p class="token__figure"><img :src="token.optimizedImageUrl" /></p>
          <p class="token__label">{{ token.name }} #{{ token.tokenId }}</p>
        </div>
      </div>

      <div v-else>
        <p>{{ $t('conn3ct-store.tokens_list_empty_label') }}</p>
      </div>
    </article>

    <template #footer>
      <actions-button
        :size="$pepper.Size.S"
        @click="back"
      >{{ $t('conn3ct-store.tokens_list_cancel_action') }}</actions-button>

      <actions-button 
        :appearance="$pepper.Appearance.PRIMARY"
        :size="$pepper.Size.S"
        :disabled="!selected"
        @click="confirm"
      >{{ $t('conn3ct-store.tokens_list_confirm_action') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal.vue'

export default {
  name: 'ModalTokensList',

  components: {
    LayoutModal
  },

  props: {
    tokens: {
      type: Array,
      required: true,
    },

    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      selected: null,
      availableOnly: true
    }
  },

  computed: {
    attrs() {
      return {
        article: true,
        classes: {
          'modal-tokens-list': true,
        },
        title: this.$t('conn3ct-store.tokens_list_title'),
        visible: this.visible
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

    tokensFiltered() {
      if (!this.availableOnly) {
        return this.tokens
      }
      return this.tokens.filter((token) => token.purchasable && !token.purchasing)
    },

    hasTokens() {
      return this.tokensFiltered.length > 0
    }
  },

  methods: {
    confirm() {
      this.$emit('select', { serial: this.selected })
    },

    select(serial) {
      if (serial === this.selected) {
        this.selected = null
        return
      }

      const token = this.tokens.find((token) => token.tokenId === serial && token.purchasable && !token.purchasing)
      if (token) {
        this.selected = serial
      }
    },

    back() {
      this.$emit('close')
    },
  },

  mounted() {
    this.selected = null
  }
}
</script>