<template>
  <details class="data-box">
    <summary class="data-box__header">
      {{ title }}
    </summary>

    <article class="data-box__content">
      <slot></slot>
    </article>
  </details>
</template>

<script>
export default {
  name: 'DataBox',

  props: {
    title: {
      type: String,
      default: ''
    },
  },

  computed: {
    hasTitle() {
      return !this.$basil.isEmpty(this.title);
    }
  }
}
</script>
