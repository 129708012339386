<template>
  <div class="product-attributes">
    <div class="product-attributes__tabs">
      <div 
        :class="{
          'product-attributes__tab': true,
          '-active': categ.value === category
        }"
        @click="() => category = categ.value"
        v-for="categ in attributeCategories"
      >{{ categ.label }}</div>
    </div>

    <ul 
      class="product-attributes__items" 
      v-if="attributes && attributes.length > 0">
      <li  
        class="product-attributes__item" 
        :key="attribute.label"
        v-for="attribute in attributes">
        <p class="product-attributes__label">{{ attribute.label }}: </p>

        <component 
          :is="attribute.tag"
          v-bind="attribute"
        >{{ attribute.value }}</component>
      </li>
    </ul>
  </div>
</template>

<script>
import SaylStore from '@/bootstrap/stores'

export default {
  name: 'ProductAttributes',

  props: {
    displaySystem: {
      default: false,
      type: Boolean,
    },

    values: {
      type: Array, 
      required: true,
    },
  },

  data() {
    return {
      category: null,
      i18n: SaylStore.bootstrap().i18n,
    }
  },

  computed: {
    _attributes() {
      let attrs = this.values.map((a) => {
        let categ = 'system'

        switch(a.type) {
          case 'string':
            categ = 'property'
            break

          case 'number': 
            categ = 'stat'
            break
        }

        return {
          categ,
          type: a.type,
          label: a.label,
          value: a.value
        }
      }).filter(a => {
        return [
          a.label !== 'Token Type ID',
          a.label !== 'Mint Number'
        ].find(v => v === false) == null
      })

      return attrs.map((a) => {
        let ret = {
          categ: a.categ,
          class: 'product-attributes__value',
          label: a.label,
          type: a.type,
          tag: 'span',
          value: a.value,
        }

        if(a.type === 'media') {
          ret.value = a.value.replace('ipfs://', 'https://ipfs.sayl.cloud/ipfs/')
          ret.tag = 'a'
          ret.href = ret.value
          ret.target = '_blank'
          ret.class = ret.class + ' --anchor'
        }

        if(a.type === 'timestamp') {
          ret.value = this.i18n.formatDate(new Date(a.value))
        }

        return ret
      })
    },

    attributes() {
      let ret = this.attributeCategories.find(categ => categ.value === this.category)
      return ret ? ret.values : []
    },

    attributeCategories() {
      let order = ['property', 'stat', 'boost', 'system']

      let categ = []

      this._attributes.forEach(r => {
        if(r.type === 'system' && !this.displaySystem) {
          return
        }

        let f = categ.find(cat => cat.value === r.categ)

        if(!f) {
          f = {
            value: r.categ,
            label: r.categ.charAt(0).toUpperCase() + r.categ.substring(1),
            values: [],
            position: order.indexOf(r.categ)
          }

          categ.push(f)
        }

        f.values.push(r)
      })

      categ = categ.sort((a, b) => a.position - b.position)
      return categ
    },
  },

  methods: {
    getAttributeValue(value) {
      let ret = value
      let regex = new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/gm)

      if(value.match(regex)) {
        ret = new Date(value)
        ret = this.$basil.i18n.datetime(ret)
      }

      return ret
    }
  },

  mounted() {
    if(this.category == null && this.attributeCategories && this.attributeCategories.length > 0) {
      this.category = this.attributeCategories[0].value
    }
  }
}
</script>