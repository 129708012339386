<template>
  <div class="product-vouchers">
    <div 
      class="product-vouchers__voucher" 
      :key="voucher.id"
      v-for="voucher in values">
      <div class="product-vouchers__row --between">
        <p class="product-vouchers__name">{{ getName(voucher) }}</p>

        <div 
          class="product-vouchers__tags" 
          v-if="voucher.isRecurring || voucher.onClaim">
          <data-tag
            class="product-vouchers__tag"
            v-if="voucher.isRecurring"
          >{{ $t('conn3ct-store.voucher_is_recurring', { amount: getRecurrentAmount(voucher) }) }}</data-tag>

          <data-tag
            class="product-vouchers__tag"
            v-if="voucher.onClaim"
          >{{ $t('conn3ct-store.voucher_on_claim', { amount: getOnClaimAmount(voucher) }) }}</data-tag>
        </div>
      </div>

      <div class="product-vouchers__row" v-if="voucher.form || voucher.to">
        <p 
          class="product-vouchers__meta" 
          v-if="voucher.from">
          {{ $t('conn3ct-store.voucher_from_date', { date: $basil.i18n.date(voucher.from) }) }}
        </p>

        <p 
          class="product-vouchers__meta" 
          v-if="voucher.to">
          {{ $t('conn3ct-store.voucher_to_date', { date: $basil.i18n.date(voucher.to) }) }}
        </p>

        <p 
          class="product-vouchers__meta" 
          v-if="$basil.get(voucher, 'generation.validityPeriod', null)">
          {{ $t('conn3ct-store.voucher_days_validity_period', { days: voucher.generation.validityPeriod }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductVouchers',

  props: {
    values: { 
      type: Array | null
    }
  },

  methods: {
    getName(voucher) {
      // TODO: add translation if needed
      return this.$basil.get(voucher, `name.all`)
    },

    getOnClaimAmount(voucher) {
      return this.$basil.get(voucher, 'generation.claim.amount', 1)
    },

    getRecurrentAmount(voucher) {
      return this.$basil.get(voucher, 'generation.recurrence.amount', 1)
    }
  }
}
</script>
