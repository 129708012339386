<template>
  <div 
    class="product-details" 
    v-if="hasContent">
    <div 
      class="product-details__description"
      v-if="hasDescription">
      <h4 class="product-details__title">{{ $t('conn3ct-store.product_description_title') }}</h4>
      <p class="product-details__descr" v-html="description"></p>
    </div>

    <!-- Attributes -->
    <details 
      class="product-details__section --attributes"
      v-if="hasAttributes">      
      <summary class="product-details__summary">{{ $t('conn3ct-store.product_attributes_title') }}</summary>

      <product-attributes
        display-system
        :values="product.nftAttributes"
      />
    </details>

    <!-- Triggers / Perks -->
    <details 
      class="product-details__section --triggers"
      v-if="hasTriggers">
      <summary class="product-details__summary">{{ $t('conn3ct-store.perks_title') }}</summary>

      <div class="product-details__content">
        Display the perks
      </div>
    </details>

    <!-- Vouchers generation -->
    <details
      class="product-details__section --vouchers"
      v-if="hasVouchers">
      <summary class="product-details__summary">{{ $t('conn3ct-store.vouchers_title') }}</summary>

      <product-vouchers
        :values="vouchers"
      />
    </details>
  </div>
</template>

<script>
import ProductAttributes from './product-attributes.vue'
import ProductVouchers from './product-vouchers.vue'

export default {
  name: 'ProductDetail',

  components: {
    ProductAttributes,
    ProductVouchers,
  },

  props: {
    nft: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    triggers: {
      type: Array | null,
      default: null
    },
    vouchers: {
      type: Array | null,
      default: null,
    },
  },

  data() {
    return {
      category: null,
    }
  },

  computed: {
    description() {
      let ret = this.$basil.get(this.product, 'description')
      return !this.$basil.isNil(ret) ? ret.split('\n').join('<br />') : null
    },

    hasAttributes() {
      return this.product && this.product.nftAttributes && this.product.nftAttributes.length > 0
    },

    hasContent() {
      return [
        this.hasAttributes,
        this.hasDescription,
        this.hasTriggers,
        this.hasVouchers,
      ].find(c => c === true) != null
    },

    hasDescription() {
      return this.$basil.get(this.product, 'description') != null
    },

    hasTriggers() {
      return false
      return this.triggers && this.triggers.length > 0
    },

    hasVouchers() {
      return this.vouchers && this.vouchers.length > 0
    }
  }
}
</script>
