<template>
  <div :class="classes">
      <!-- Price -->
      <div 
        class="product-price__tag product-price__price"
        v-if="visible || (!inPromo && visible)"
      >{{ price }}</div>

      <!-- Promo Price -->
      <div 
        class="product-price__tag product-price__promo"
        v-if="inPromo && visible"
      >{{ promo }}</div>
      
      <data-tag 
        class="product-price__promotag"
        :size="$pepper.Size.M"
        v-if="inPromo && inStock && taggable"
      >{{ $t('conn3ct-store.promotion') }}</data-tag>

      <data-tag 
        class="product-price__promotag"
        :size="$pepper.Size.M"
        v-if="!inStock && taggable"
      >{{ $t('conn3ct-store.no_stock') }}</data-tag>
  </div>
</template>

<script>
import CurrencyMixin from '@/helpers/currency'

export default {
  name: 'ProductPrice',

  mixins: [
    CurrencyMixin
  ],

  props: {
    autoHide: {
      type: Boolean,
      default: false
    },

    qty: {
      type: Number,
      default: 0
    },

    product: {
      type: Object
    },

    signed: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'm'
    },

    taggable: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    classes() {
      return {
        'product-price': true,
        '--promo': this.inPromo,

        '-l': this.size === 'l'
      }
    },

    inPromo() {
      return this.$basil.get(this.item, 'inPromo')
    },

    inStock() {
      return this.$basil.get(this.item, 'inStock', true)
    },

    isHidden() {
      return this.item.hidden
    },

    visible() {
      return this.autoHide === false || (this.autoHide === true && (this.product.unitPrice !== 0 && !isNaN(this.priceValue)))
    },

    item() {
      return this.variant ? this.variant : this.product
    },

    price() {
      return this.toCurrency(this.$basil.get(this.product, 'unitPrice', 0), this.signed)
    },

    promo() {
      return this.toCurrency(this.$basil.get(this.product, 'promoPrice', null), this.signed)
    }
  },
}
</script>
