<template>
  <main>
    <tokens-list-modal
      v-if="tokens !== null && modalTokensVisible"
      :visible="modalTokensVisible"
      :tokens="tokens"
      @select="(args) => directBuy(args.serial)"
      @close="modalTokensVisible = false">
    </tokens-list-modal>

    <section
      class="view-catalog-product container"
      :data-id="$basil.get(product, 'id')"
      :data-slug="$basil.get(product, 'slug')"
      :id="productSlug">
      <!-- loading -->
      <template v-if="loading">
        <div class="view-catalog-product__loader">
          <ui-loader />
        </div>
      </template>

      <template v-else>
        <!-- Back Button -->
        <h3 class="view-catalog-product__header">
          <actions-button
            :appearance="$pepper.Appearance.SUBTLE"
            class="view-catalog-product__back-button"
            icon-pre="arrow-left"
            :size="$pepper.Size.S"
            @click="close">
            <span class="view-catalog-product__title">{{ $t('conn3ct-store.product_title') }}</span>
          </actions-button>
        </h3>

        <section class="view-catalog-product__body">
          <product-card 
            hide-descr
            :interactive="false"
            :product="product" 
          />

          <div class="view-catalog-product__right">
            <actions-button
              :appearance="$pepper.Appearance.PRIMARY"
              class="view-catalog-product__direct-buy"
              :data-price="price"
              :disabled="!inStock"
              :size="$pepper.Size.L"
              @click="() => directBuy()">
              <div class="direct-buy-label">
                <ui-icon 
                  class="direct-buy-icon" 
                  glyph="bag" 
                />

                {{ $t('conn3ct-store.direct_buy', { price }) }}
              </div>
            </actions-button>

            <product-details 
              :nft="nft"
              :product="product"
              :triggers="triggers"
              :vouchers="vouchers"
            />
          </div>

          <!-- To remove and don't forgot the data-box -->
          <data-box
            class="view-catalog-product__metatada"
            :title="$t('conn3ct-store.metadata')"
            v-if="false">
            <div class="view-catalog-product__data-row">
              <span class="label">{{ $t('conn3ct-store.token_id') }}:</span>

              <a 
                class="value -is-link" 
                :href="explorerUrl" 
                target="__blank"
              >{{ tokenId }}</a>
            </div>

            <div class="view-catalog-product__data-row">
              <span class="label">{{ $t('conn3ct-store.token_standard') }}:</span>

              <span class="value">ERC-721</span>
            </div>

            <div class="view-catalog-product__data-row">
              <span class="label">{{ $t('conn3ct-store.token_blockhain') }}:</span>

              <span class="value">Hedera</span>
            </div>
          </data-box>

          <div v-if="false">
              <!-- Description display -->
              <div
                class="view-catalog-product__description"
                v-if="!$basil.isNil(description)">
                <h4 class="view-catalog-product__section-title">{{ $t('conn3ct-store.product_description_title') }}</h4>

                <p 
                  class="view-catalog-product__description-content" 
                  v-html="description"
                ></p>
              </div>

              <!-- Trigger to display => Web3 Vouchers -->
              <div
                class="view-catalog-product__perks"
                v-if="!$basil.isNil(triggers) && triggers.length">
                <h4 class="view-catalog-product__section-title">{{ $t('conn3ct-store.perks_title') }}</h4>

                <ul class="view-catalog-product__perks-list">
                  <li
                    class="view-catalog-product__perk"
                    :key="$basil.uniqId(trigger.id)"
                    v-for="trigger in triggers">
                    <h4 class="view-catalog-product__perk-name">{{ $basil.get(trigger, 'perk.name') }}</h4>

                    <div class="view-catalog-product__perk-description">
                      <ui-icon 
                        class="icon" 
                        glyph="check" 
                      />

                      <p v-html="$basil.get(trigger, 'perk.client_message')"></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        </section>
      </template>
    </section>
  </main>
</template>

<script>
import CurrencyMixin from '@/helpers/currency'

import TokensListModal from '@/components/modals/tokens-list'
import ProductCard from '@/modules/catalog/components/product/card'
import ProductDetails from '@/modules/catalog/components/product/details'
import DataBox from '@/modules/catalog/components/ui/data-box'

import Config from '@/config'

export default {
  name: 'CatalogProductView',

  mixins: [ CurrencyMixin ],

  inject: [
    '$catalog',
    '$nft'
  ],

  components: {
    TokensListModal,
    ProductCard,
    ProductDetails,
    DataBox,
  },

  data() {
    return {
      loading: true,
      item: null,
      nft: null,
      product: null,
      categProd: null,
      triggers: null,
      vouchers: null,
      tokens: null,
      modalTokensVisible: false
    }
  },

  computed: {
    description() {
      let ret = this.$basil.get(this.product, 'description')
      return !this.$basil.isNil(ret) ? ret.split('\n').join('<br />') : null
    },

    explorerUrl() {
      return `${Config.hederaExplorerUrl}tokens/${this.tokenId}`
    },

    inStock() {
      return this.stock > 0
    },

    price() {
      let v = this.$basil.get(this.product, 'unitPrice', 0)

      let promoPrice = this.$basil.get(this.product, 'promoPrice', null)
      if(promoPrice != null && promoPrice > 0) {
        v = promoPrice
      }

      return this.toCurrency(v)
    },

    productSlug() {
      return `product-${this.$basil.get(this.product, 'slug')}`
    },

    stock() {
      return this.$basil.get(this.product, 'stock', 0)
    },

    tokenId() {
      return this.$basil.get(this.product, 'nft.token_id', null)
    },

    valid() {
      let ret = this.item && this.item.validate()

      return ret
    },
  },

  methods: {
    close() {
      this.$router.replace({ name:'sayl-front-catalog.catalog' }).catch(() => {})
    },

    directBuy(serial = null) {
      if (!this.product.nftMintingFromPool) {
        this.$router.push({ 
          name: 'sayl-front-catalog.checkout', 
          params: {
            embed: this.$route.params.embed,
            product: this.product.id
          }
        }).catch(() => {})
      } else if (serial) {
        this.modalTokensVisible = false
        this.$router.push({ 
          name: 'sayl-front-catalog.checkout', 
          params: {
            embed: this.$route.params.embed,
            product: `${this.product.id}.${serial}`,
          }
        }).catch(() => {})
      } else {
        this.modalTokensVisible = true
      }
    },
  },

  async mounted() {
    let productId = this.$route.params.product

    if(!productId) {
      return
    }

    this.$bus.$emit('injekt.sp.product', productId)

    
    document.body.classList.add(this.productSlug)
    
    try {
      this.loading = true
      this.product = this.$nft.findById(productId)
      let prod = this.$catalog.product.getProductById(productId)

      this.product.mergeAdditionnalData(prod)

      let { nft, triggers, tokens } = await this.$nft.getNft(this.product.nftId, this.product.nftMintingFromPool)

      this.nft = nft
      this.triggers = triggers
      this.vouchers = this.product.web3Vouchers
      this.tokens = tokens
    } catch(e) {
      $console.error(e)
      this.close()
    } finally {
      this.loading = false
    }
  },

  beforeDestroy() {
    document.body.classList.remove(this.productSlug)
  }
}
</script>
